import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import useBaseUtils from "~/functions/useBaseUtils";
import CiqTranscriptTable from "~/components/CiqTranscriptTable.vue";
export default {
  __name: 'transcripts',
  emits: ["closechart"],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var _useNuxtApp = useNuxtApp(),
      $store = _useNuxtApp.$store;
    var _useBaseUtils = useBaseUtils(),
      freeTier = _useBaseUtils.freeTier,
      refCode = _useBaseUtils.refCode,
      sectionWrapperStyle = _useBaseUtils.sectionWrapperStyle;
    var loading = computed(function () {
      return $store.state.ciq.fetchingTranscriptList;
    });
    var headers = computed(function () {
      var object = $store.state.ciq.transcriptList[0] || {};
      var properties = Object.keys(object);
      return properties.reduce(function (acc, property) {
        if (property === "mostimportantdateutc") {
          acc.push({
            text: "Event Date",
            value: property,
            align: "left",
            "class": "font-weight-bold text-body-2",
            cellClass: "common-cell rounded-0 event-date"
          });
        }
        if (property === "headline") {
          acc.push({
            text: "Event",
            value: property,
            align: "left",
            "class": "eventName font-weight-bold text-body-2",
            cellClass: "common-cell font-weight-medium rounded-0"
          });
        }
        return acc;
      }, []);
    });
    var transcripts = computed(function () {
      return $store.state.ciq.transcriptList || [];
    });
    var footer = computed(function () {
      return {
        itemsPerPageOptions: [10, 20, 30, 40, -1]
      };
    });
    onMounted(function () {
      emit("closechart");
    });
    return {
      __sfc: true,
      emit: emit,
      $store: $store,
      freeTier: freeTier,
      refCode: refCode,
      sectionWrapperStyle: sectionWrapperStyle,
      loading: loading,
      headers: headers,
      transcripts: transcripts,
      footer: footer,
      CiqTranscriptTable: CiqTranscriptTable
    };
  }
};